@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,500;6..12,600;6..12,700&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
	font-size: 10px;
}

:root {
	--background-color: #e8e9eb;
	--dark-color: #313638;
	--accent: #006989;
	--orange-accent: #e4b363;
	--grey-accent: #a3bac3;
	--white: #fff;
	--black: #000;
	--box-shadow: rgba(0, 0, 0, 0.5);
}

body {
	font-family: "Nunito Sans", sans-serif;
	font-size: 1.6rem;
	line-height: 1.5;
	font-weight: 400;
	text-wrap: balance;
	color: var(--black);
	background-color: var(--background-color);
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important; /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important; /* 3 */
}

.wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: clamp(2rem, 0.765rem + 3.529vw, 5rem);
	max-width: 120rem;
	width: calc(100% - 4rem);
	margin: 0 auto;
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

h1,
h2 {
	padding-inline: clamp(1rem, 0.5rem + 1.25vw, 2rem);
}

h1 {
	font-size: clamp(3.6rem, 3rem + 1.5vw, 4.8rem);
	font-weight: 700;
	margin: 0;
}

h2 {
	font-size: clamp(2rem, 1.2rem + 2vw, 3.6rem);
	font-weight: 600;
}

h2 span {
	white-space: nowrap;
}

h3 {
	font-size: clamp(1.6rem, 0.9rem + 1.75vw, 3rem);
	line-height: 1;
	font-weight: 700;
}

#bio,
#projects,
#contact {
	width: 100%;
}

#bio,
#projects {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#bio {
	text-align: center;
	padding: clamp(1rem, 0.5rem + 1.25vw, 2rem) 0;
	margin-top: clamp(2rem, 0.765rem + 3.529vw, 5rem);
	background-color: var(--grey-accent);
}

#projects ul,
#projects li {
	width: 100%;
}

#projects ul {
	display: flex;
	flex-direction: column;
	gap: clamp(2rem, 0.765rem + 3.529vw, 5rem);
}

#projects li {
	display: flex;
	flex-direction: column;
	background-color: var(--accent);
	color: var(--white);
	box-shadow: 10px 5px 10px var(--box-shadow);
}

#projects h3 {
	padding: clamp(1rem, 0.5rem + 1.25vw, 2rem);
}

#projects a {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	height: clamp(11.5rem, 0.794rem + 30.588vw, 37.5rem);
	transition: 0.3s all ease-in-out;
}

#bumble a {
	background: url("../assets/bumble.png") top center no-repeat;
	background-size: cover;
}

#fbb a {
	background: url("../assets/fbb.png") top center no-repeat;
	background-size: cover;
}
#lrc a {
	background: url("../assets/lrc.png") top center no-repeat;
	background-size: cover;
}
#macys a {
	background: url("../assets/macys.png") top center no-repeat;
	background-size: cover;
}
#mativ a {
	background: url("../assets/mativ.png") top center no-repeat;
	background-size: cover;
}

#contact {
	padding-bottom: clamp(2rem, 0.5rem + 3.75vw, 5rem);
}

#contact article {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	background-color: var(--accent);
	padding: 2rem;
}

#contact p,
#contact a {
	margin: 0;
	line-height: 2.4rem;
	color: var(--white);
	font-weight: 600;
	text-align: center;
}

#contact a {
	display: inline-flex;
	height: 100%;
}

#contact img {
	height: 2.4rem;
	filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7477%)
		hue-rotate(187deg) brightness(113%) contrast(100%);
}

/* LazyLoad */
.LazyLoad {
	opacity: 0;
	transition: all 1s ease-in-out;
	transform: translateY(5%);
	width: 100%;
}

.is-visible {
	opacity: 1;
	transform: translateY(0);
}
